import ArrayTool from "../../lib/array-tool";


export default function reducePOIGroups(subState = [], actionName, state, action) {
    const subReducer = actionReducers[actionName];
    if (!subReducer) {
        // TODO: emit a warning?
        return subState;
    }
    return subReducer(subState, action);
}


const actionReducers = {
    append: (subState = [], { newItem }) =>
        ArrayTool.append(subState, newItem),
    update: (subState = [], { idx, changes }) =>
        ArrayTool.update(subState, idx, { ...subState[idx], ...changes }),
    moveUp: (subState = [], { idx }) =>
        ArrayTool.moveUp(subState, idx),
    moveDown: (subState = [], { idx }) =>
        ArrayTool.moveDown(subState, idx),
    remove: (subState = [], { idx }) =>
        ArrayTool.remove(subState, idx),

    upsertById: (subState = [], { item }) => {
        let existingIndex = subState.findIndex(x => x.id === item.id);
        if (existingIndex < 0) {
            // Not found, just append
            return ArrayTool.append(subState, item);
        }
        // Replace the existing item
        return ArrayTool.update(subState, existingIndex, item);
    },
};
