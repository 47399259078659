import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Alert,
    Button,
    Input,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCrosshairs,
    faPencilAlt,
    faGlobe,
    faLocationArrow,
    faTrashAlt,
    faArrowUp,
    faArrowDown,
    faEdit,
    faBars,
    faCompassDrafting,
    faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';

import * as GeoMath from "../lib/geo-math";
import {
    formatDistance,
    formatBearing,
} from "../lib/formatting";
import * as actions from "../storage/actions";
import { createPointsGroup } from "../storage/constructors";
import LocationDisplay from "./location-display";
import DistanceInput from "./distance-input";
import { useAppContext } from "../context";
import { getPOIGroupTheme } from "../map/themes";


const DEFAULT_CONTEXT = {
    showLabel: true,
    showCoordinates: true,
    showDirections: true,
    selectedGroup: "default",
};


function usePointsModalContext() {
    return useSelector(state => ({
        ...DEFAULT_CONTEXT,
        ...state?.uiState?.pointsDialog,
    }));
}

function usePointsModalContextUpdate() {
    const dispatch = useDispatch();
    return changes => dispatch(actions.uiState.pointsDialog.update(changes));
}


export default function PointsModal() {
    const { closeModal } = useAppContext();
    return (
        <Modal isOpen toggle={closeModal} size="xl">
            <ModalHeader toggle={closeModal}>
                Points
            </ModalHeader>
            <ModalBody>
                <PointsModalToolbar />
                <PointsConfigurationList />
            </ModalBody>
        </Modal>
    );
}


function PointsModalToolbar() {
    const context = usePointsModalContext();
    const updateContext = usePointsModalContextUpdate();

    const getSimpleToggleProps = (name) => {
        return {
            active: !!context[name],
            onClick: () => updateContext({ [name]: !context[name] }),
        };
    };

    return (
        <div className="mb-3">
            <div className="mb-1 d-flex align-items-center">
                <POIGroupSelector
                    value={context.selectedGroup}
                    onChange={selectedGroup => updateContext({ selectedGroup })}
                />
            </div>
            <div className="mb-1">
                <Button {...getSimpleToggleProps("showCoordinates")}>
                    Coordinates
                </Button>{" "}
                <Button {...getSimpleToggleProps("showDirections")}>
                    Directions
                </Button>{" "}
            </div>
        </div>
    );
}


function POIGroupSelector({ value, onChange }) {
    const [isOpen, toggle] = React.useState(false);
    const poiGroups = useSelector(state => state?.poiGroups || []);
    const dispatch = useDispatch();

    const currentGroupIdx = poiGroups.findIndex(x => x.id === value);
    const currentGroup = currentGroupIdx >= 0 ? poiGroups[currentGroupIdx] : null;

    const onCreateNewGroup = () => {
        const label = prompt("Group name");
        if (label === null || !label) {
            return;  // dismissed
        }
        const newGroup = createPointsGroup({ label });
        dispatch(actions.poiGroups.append(newGroup));
        onChange(newGroup.id);
    };

    const onRenameGroup = () => {
        if (currentGroupIdx < 0) {
            return;
        }
        const label = prompt("Group name", currentGroup?.label);
        if (label === null || !label) {
            return;  // dismissed
        }
        dispatch(actions.poiGroups.update(currentGroupIdx, { label }));
    };

    const onDeleteGroup = () => {
        if (currentGroupIdx < 0) {
            return;
        }
        if (!window.confirm("Delete current POI group? All points will be lost.")) {
            return;
        }
        dispatch(actions.poiGroups.remove(currentGroupIdx));
        onChange(poiGroups[0]?.id);  // Also switch to the first group
    };

    const getGroupLabel = ({ id, label }) => {
        return label || <span className="text-muted">Unnamed group {id}</span>;
    };

    const defaultLabel = (() => {
        if (currentGroup) {
            return getGroupLabel(currentGroup);
        }
        return "POI Group";
    })();

    function renderGroupColorSquare(group, idx) {
        const theme = getPOIGroupTheme(group, idx);
        return <span style={{
            background: theme?.markerStyle?.background,
            width: "1em",
            height: "1em",
            display: "inline-block",
        }} />;
    }

    return (
        <Dropdown isOpen={isOpen} toggle={() => toggle(x => !x)}>
            <DropdownToggle caret>
                {defaultLabel}
            </DropdownToggle>
            <DropdownMenu>
                {poiGroups.map((group, idx) =>
                    <DropdownItem
                        key={group.id}
                        onClick={() => onChange(group.id)}
                        active={group.id === value}
                    >
                        {renderGroupColorSquare(group, idx)}{" "}
                        {getGroupLabel(group)}
                    </DropdownItem>)}
                {!!poiGroups.length && <DropdownItem divider />}
                <DropdownItem onClick={onCreateNewGroup}>
                    <FontAwesomeIcon icon={faPlusCircle} />{" "}
                    Create new group...
                </DropdownItem>
                <DropdownItem onClick={onRenameGroup}>
                    <FontAwesomeIcon icon={faPencilAlt} />{" "}
                    Rename group
                </DropdownItem>
                <DropdownItem onClick={onDeleteGroup}>
                    <FontAwesomeIcon icon={faTrashAlt} />{" "}
                    Delete group
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}


function PointsConfigurationList() {

    const dispatch = useDispatch();
    const context = usePointsModalContext();
    const poiGroups = useSelector(state => state?.poiGroups || []);
    const { pickerTool } = useAppContext();

    const { selectedGroup } = context;
    const currentGroupIdx = poiGroups.findIndex(x => x.id === selectedGroup);
    const currentGroup = currentGroupIdx >= 0 ? poiGroups[currentGroupIdx] : null;
    const theme = getPOIGroupTheme(currentGroup || {}, currentGroupIdx || 0);

    if (!currentGroup) {
        return (
            <div className="p-5 text-center text-muted border border-secondary">
                Please select a POI group.
            </div>
        );
    }

    const { points } = currentGroup;

    if (points.length <= 0) {
        return (
            <div className="p-5 text-center text-muted border border-secondary">
                There are no points in this group
            </div>
        );
    }

    const onPointChange = (idx, changes) => {
        dispatch(actions.points.update(idx, changes));
    };

    const onPointDelete = (idx) => {
        dispatch(actions.points.remove(idx));
    };

    const onPointMoveUp = (idx) => {
        dispatch(actions.points.moveUp(idx));
    };

    const onPointMoveDown = (idx) => {
        dispatch(actions.points.moveDown(idx));
    };

    return (
        <div>
            {points.map((point, idx) => (
                <PointConfigurationRow
                    key={point.id || `point-${idx}`}
                    point={point}
                    nextPoint={points[idx + 1] || null}
                    idx={idx}
                    pickerTool={pickerTool}
                    onChange={onPointChange.bind(this, idx)}
                    onDelete={onPointDelete.bind(this, idx)}
                    onMoveUp={onPointMoveUp.bind(this, idx)}
                    onMoveDown={onPointMoveDown.bind(this, idx)}
                    isFirst={idx === 0}
                    isLast={idx === points.length - 1}
                    groupTheme={theme}
                />
            ))}
        </div>
    );
}


function PointConfigurationRow({
    point,
    nextPoint,
    idx,
    onChange,
    onDelete,
    onMoveUp,
    onMoveDown,
    pickerTool,
    isFirst,
    isLast,
    groupTheme,
}) {

    const [selectedTool, setSelectedTool] = React.useState(null);
    const context = usePointsModalContext();

    const getToolSwitchProps = toolName => {
        const isActive = toolName === selectedTool;
        return {
            active: isActive,
            onClick: () => {
                setSelectedTool(isActive ? null : toolName);
            },
        };
    };

    const renderMenuDropdown = () => {
        const menuProps = {
            point,
            idx,
            onChange,
            onDelete,
            onMoveUp,
            onMoveDown,
            pickerTool,
            isFirst,
            isLast,
            getToolSwitchProps,
        };
        return <PointActionsMenu {...menuProps} />;
    };

    const renderAddAbsoluteToolPane = () => {
        return (
            <div className="m-3 p-3 border border-success">
                <h4>Add new point</h4>
                <div>Enter coordinates of the new point</div>
                <div className="text-warning">Coming soon!</div>
                <div>
                    <Button color="success">Add point</Button>{" "}
                    <Button onClick={() => setSelectedTool(null)}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    const onCloseModal = () => setSelectedTool(null);

    return (
        <div className="p-1">
            <div className="d-flex align-items-center w-100">
                <div>
                    <MarkerNumberBubble theme={groupTheme}>
                        {idx + 1}
                    </MarkerNumberBubble>
                </div>
                <div className="flex-grow-1 mx-2">
                    <div className="fs-5 me-2">
                        {point.label || <span className="text-muted">Unnamed point</span>}
                    </div>
                    {context.showCoordinates &&
                        <div style={{ opacity: .8 }}>
                            <LocationDisplay location={point.location} />
                        </div>}
                </div>
                <div>
                    {renderMenuDropdown()}
                </div>
            </div>

            {!!point.showRadius &&
                <div className="p-1 d-flex flex-row align-items-center">
                    <div className="me-2">
                        <Label check className="m-0">
                            <Input
                                type="checkbox"
                                checked={point.showRadius}
                                onChange={event => onChange({
                                    ...point,
                                    showRadius: event.target.checked,
                                })}
                            />
                            {" "}Distance:
                        </Label>
                    </div>
                    <DistanceInput
                        value={point.radius || 0}
                        onValueChange={radius => onChange({ ...point, radius })}
                        style={{ maxWidth: 120, textAlign: 'right' }}
                    />
                </div>}

            {selectedTool === "edit" &&
                <PointCoordinatesEditPane point={point} onClose={onCloseModal} />}

            {context.showDirections &&
                <DestintationDisplay point={point} nextPoint={nextPoint} />}

            {selectedTool === "add-absolute" && renderAddAbsoluteToolPane()}
            {selectedTool === "add-relative" &&
                <AddRelativePointModal point={point} onClose={onCloseModal} />}

        </div>
    );
}


function MarkerNumberBubble({ theme, children }) {
    const style = {
        background: "#3f51b5",
        width: 30,
        height: 30,
        borderRadius: '50%',
        color: "#fff",
        ...theme.markerStyle,
    };
    return (
        <div className="d-flex flex-column align-items-center justify-content-center"
            style={style}>
            {children}
        </div>
    );
}


function DestintationDisplay({ point, nextPoint }) {
    if (!nextPoint) {
        return null;
    }
    const nextPointDistance = GeoMath.inverse(point.location, nextPoint.location);
    const className = [
        "my-3",
        "p-2",
        "border",
        "border-info",
        "d-flex",
        "flex-row",
        "align-items-center",
        "justify-content-center"
    ].join(" ");
    return (
        <div className={className}>
            <div className="ms-1">
                <span className="text-muted">Bearing:</span>{" "}
                <BearingIcon bearing={nextPointDistance.initialBearing} size="1x" />{" "}
                <code>{formatBearing(nextPointDistance.initialBearing)}</code>
            </div>
            <div className="ms-3">
                <span className="text-muted">Distance:</span>{" "}
                <code>{formatDistance(nextPointDistance.distance)}</code>
            </div>
        </div>
    );
}


function BearingIcon({ bearing, ...props }) {
    return (
        <FontAwesomeIcon
            style={{ transform: `rotate(${bearing}deg)` }}
            icon={faArrowUp}
            {...props}
        />
    );
}


function PointCoordinatesEditPane({ point, onClose }) {
    return (
        <Modal isOpen toggle={onClose} size="lg">
            <ModalHeader toggle={onClose}>
                Edit point
            </ModalHeader>
            <ModalBody>
                TODO
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onClose}>
                    Save
                </Button>
                <Button onClick={onClose}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};


function AddRelativePointModal({ point, onClose }) {
    return (
        <Modal isOpen toggle={onClose} size="lg">
            <ModalHeader toggle={onClose}>
                Add relative point
            </ModalHeader>
            <ModalBody>
                <p>
                    Add a point by entering bearing and distance from
                    an existing point.
                </p>
                <Alert color="warning">
                    <strong>Coming soon!</strong> This feature has not been implemented yet
                </Alert>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={onClose}>
                    Create point
                </Button>
                <Button onClick={onClose}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};


function PointActionsMenu({
    point,
    idx,
    onChange,
    onDelete,
    onMoveUp,
    onMoveDown,
    pickerTool,
    isFirst,
    isLast,
    getToolSwitchProps,
}) {
    const [menuOpen, setMenuOpen] = React.useState(false);

    const onPickFromMap = () => {
        pickerTool.activate(location => {
            onChange({ ...point, location });
        });
    };

    const onPickCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            onChange({
                ...point,
                location: [latitude, longitude],
            });
        });
    };

    const onRename = () => {
        const label = prompt("New label", point.label || "");
        if (label === null) {
            return;  // dismissed
        }
        onChange({ ...point, label });
    }

    return (
        <Dropdown
            isOpen={menuOpen}
            toggle={() => setMenuOpen(state => !state)}
        >
            <DropdownToggle color="primary">
                <FontAwesomeIcon icon={faBars} />
            </DropdownToggle>

            <DropdownMenu>

                <DropdownItem header>
                    Tools
                </DropdownItem>

                <DropdownItem
                    active={!!point.showRadius}
                    onClick={() => onChange({ ...point, showRadius: !point.showRadius })}
                >
                    <FontAwesomeIcon icon={faCompassDrafting} />{" "}
                    {point.showRadius
                        ? "Hide distance from point"
                        : "Show distance from point"}

                </DropdownItem>

                <DropdownItem header>
                    Edit
                </DropdownItem>

                <DropdownItem onClick={onRename}>
                    <FontAwesomeIcon icon={faPencilAlt} />{" "}
                    Rename
                </DropdownItem>

                <DropdownItem {...getToolSwitchProps("edit")}>
                    <FontAwesomeIcon icon={faEdit} />{" "}
                    Edit coordinates
                </DropdownItem>

                <DropdownItem onClick={onPickFromMap}>
                    <FontAwesomeIcon icon={faCrosshairs} />{" "}
                    Pick from map
                </DropdownItem>

                <DropdownItem onClick={onPickCurrentLocation}>
                    <FontAwesomeIcon icon={faGlobe} />{" "}
                    Use current location
                </DropdownItem>

                <DropdownItem header>
                    Add point
                </DropdownItem>

                <DropdownItem {...getToolSwitchProps("add-relative")}>
                    <FontAwesomeIcon icon={faLocationArrow} />{" "}
                    From distance / bearing
                </DropdownItem>

                <DropdownItem header>
                    Arrange
                </DropdownItem>

                <DropdownItem onClick={onMoveUp} disabled={isFirst}>
                    <FontAwesomeIcon icon={faArrowUp} />{" "}
                    Move up
                </DropdownItem>

                <DropdownItem onClick={onMoveDown} disabled={isLast}>
                    <FontAwesomeIcon icon={faArrowDown} />{" "}
                    Move down
                </DropdownItem>

                <DropdownItem header>
                    Danger zone
                </DropdownItem>

                <DropdownItem onClick={onDelete}>
                    <FontAwesomeIcon icon={faTrashAlt} />{" "}
                    Delete
                </DropdownItem>

            </DropdownMenu>
        </Dropdown>
    );
}
