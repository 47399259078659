import ArrayTool from "../../lib/array-tool";
import { createPointsGroup } from "../constructors";


export default function reducePoints(subState = [], actionName, state, action) {
    // console.log("reducePoints");

    const subReducer = actionReducers[actionName];
    if (!subReducer) {
        console.warn("Points sub reducer not found for", actionName);
        return subState;
    }

    const newSubState = [ ...subState ];

    const [ selectedGroupIdx, selectedGroup ] = (() => {

        const selectedGroupID = state?.uiState?.pointsDialog?.selectedGroup;

        // Try and find a group with this ID
        if (selectedGroupID) {
            const selectedGroupIdx = subState.findIndex(x => x.id === selectedGroupID);
            if (selectedGroupIdx >= 0) {
                return [ selectedGroupIdx, subState[selectedGroupIdx] ];
            }
        }

        // If no current group found, and there is at least one group,
        // return the first one
        if (subState.length >= 1) {
            return [ 0, subState[0] ];
        }

        // Create a new default group
        const newGroup = createPointsGroup({
            id: "default",
            label: "Default",
        });
        newSubState.push(newGroup);
        return [ 0, newSubState[0] ];

    })();

    // console.log("Updating points for group", selectedGroup, action);
    return ArrayTool.update(newSubState, selectedGroupIdx, {
        ...selectedGroup,
        points: subReducer(selectedGroup.points, action),
    });
}


const actionReducers = {
    append: (subState = [], { newItem }) =>
        ArrayTool.append(subState, newItem),
    update: (subState = [], { idx, changes }) =>
        ArrayTool.update(subState, idx, { ...subState[idx], ...changes }),
    moveUp: (subState = [], { idx }) =>
        ArrayTool.moveUp(subState, idx),
    moveDown: (subState = [], { idx }) =>
        ArrayTool.moveDown(subState, idx),
    remove: (subState = [], { idx }) =>
        ArrayTool.remove(subState, idx),
};
