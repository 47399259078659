import { applyMiddleware, createStore } from "redux";
import { save, load } from "redux-localstorage-simple";
import reducer from "./reducer";


const LOCALSTORAGE_CONFIG = {
    states: ["points", "settings", "uiState", "poiGroups"],
    namespace: "zengis",
    namespaceSeparator: ":",
};


const createStoreWithMiddleware = applyMiddleware(
    // Save to localStorage from middleware
    save(LOCALSTORAGE_CONFIG),
)(createStore);

function loadState() {
    let state = load(LOCALSTORAGE_CONFIG);
    // console.log("Loaded state:", state);

    // Migrate points to point groups
    if (state.points && !state.poiGroups) {
        const { points, ...rest } = state;
        state = {
            ...rest,
            poiGroups: [
                {
                    id: "default",
                    label: "Default",
                    points: state.points,
                },
            ],
        }
    }

    return state;
}

const store = createStoreWithMiddleware(
    reducer,

    // Load initial state from localstorage
    loadState(),

    // Enable React DevTools integration
    (typeof window !== "undefined")
    ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    : undefined,
);

export default store;
