import * as React from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledAccordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Input,
    Label,
    FormGroup,
} from "reactstrap";
import { useSelector } from "react-redux";


export default function ExportModal({ onClose }) {
    return (
        <Modal isOpen toggle={onClose} size="xl">
            <ModalHeader toggle={onClose}>
                Export
            </ModalHeader>
            <ModalBody>
                <ExportUI />
            </ModalBody>
        </Modal>
    );
}


function ExportUI() {

    const poiGroups = useSelector(state => state?.poiGroups || []);
    const [selectedObjects, setSelectedObjects] = React.useState({
        poiGroups: {},
    });

    React.useEffect(() => {
        setSelectedObjects({
            poiGroups: Object.fromEntries(
                poiGroups.map(group => [group.id, true])
            ),
        })
    }, [poiGroups]);

    const togglePOIGroup = (groupId) =>
        setSelectedObjects(
            selectedObjects => {
                let { poiGroups } = selectedObjects;
                poiGroups = { ...poiGroups, [groupId]: !poiGroups[groupId] };
                return { ...selectedObjects, poiGroups };
            }
        );

    const doExport = () => {
        let data = {
            zengis: {
                version: 1,
                exportTimestamp: (new Date()).toISOString(),
            },
            data: {
                poiGroups: poiGroups.filter(group => selectedObjects.poiGroups[group.id]),
            },
        };
        downloadJson(data, "zengis-export.json");
    };

    return (
        <>
            <div className="mb-3">

                <UncontrolledAccordion
                    stayOpen
                    defaultOpen={["section-poi-groups"]}
                >
                    <AccordionItem>
                        <AccordionHeader targetId="section-poi-groups">
                            POI groups
                        </AccordionHeader>
                        <AccordionBody accordionId="section-poi-groups">

                            {poiGroups.map(group => (
                                <FormGroup check key={group.id}>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={selectedObjects.poiGroups[group.id]}
                                            onChange={() => togglePOIGroup(group.id)}
                                        />{" "}
                                        {group.label}
                                    </Label>
                                </FormGroup>
                            ))}

                        </AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>

            </div>
            <div className="text-end">
                <Button color="primary" onClick={doExport}>
                    Export and download
                </Button>
            </div>
        </>
    );
}


function downloadJson(data, filename) {
    let blob = new Blob(
        [JSON.stringify(data)],
        { type: "application/json" },
    );
    let elem = document.createElement("a");
    elem.href = URL.createObjectURL(blob);
    elem.download = filename;
    elem.click();
}
