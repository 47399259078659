import * as React from "react";
import { Helmet } from "react-helmet";
import {
    MapContainer,
    Marker,
    useMapEvents,
    Popup,
    ZoomControl,
    ScaleControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faTrashAlt,
    faArrowAltCircleUp,
    faMapMarkerAlt,
    faCog,
    faPlusCircle,
    faPencilAlt,
    faBars,
    faQuestionCircle,
    faDownload,
    faUpload,
} from '@fortawesome/free-solid-svg-icons';
import Div100vh from 'react-div-100vh';
import { useSelector, useDispatch } from "react-redux";

import * as GeoMath from "./lib/geo-math";
import "./map/icons/default";
import makeCustomIcon from "./map/icons/custom";
import GeodesicLine from "./map/geodesic-line";
import GeodesicCircle from "./map/geodesic-circle";
import OsmTileLayer from "./map/layers/osm";
import {
    formatLatLonDMS,
    formatDistance,
    formatBearing,
} from "./lib/formatting";
import Provider from "./storage/provider";
import * as actions from "./storage/actions";
import PointsModal from "./ui/points-modal";
import SettingsModal from "./ui/settings-modal";
import ExportModal from "./ui/export-modal";
import ImportModal from "./ui/import-modal";
import AboutModal from "./ui/about-modal";
import { createPoint } from "./storage/constructors";
import CoordinatesInputModal from "./ui/coordinates-input-modal";
import { ReactComponent as Logo } from "../images/logo.svg";
import { AppContextProvider } from "./context";
import { getPOIGroupTheme } from "./map/themes";
import ErrorBoundary from "./ui/error-boundary";

const DEFAULT_RESOLUTION = 180;
const ROUTE_LINE_STYLE = {
    color: "#3f51b5",
};
const DISTANCE_CIRCLE_STYLE = {
    color: "#3f51b5",
    opacity: 0.5,
    stroke: true,
    weight: 3,
    fill: true,
    fillColor: "#3f51b5",
    fillOpacity: .1,
};

const PICKER_TOOL_ADD_ONE = "add";
const PICKER_TOOL_ADD_MANY = "addMany";
// const PICKER_TOOL_UPDATE_LOCATION = ""; // FIXME

const TAB_MAP = "map";
const TAB_POINTS = "points";
const TAB_SETTINGS = "settings";
const TAB_EXPORT = "export";
const TAB_IMPORT = "import";
const TAB_INPUT_COORDINATES = "input-coordinates";
const TAB_ABOUT = "about";

const PRODUCT_TITLE = "ZenGIS";


export default function MapToolPage() {
    return (
        <>
            <Helmet>
                <title>{PRODUCT_TITLE}</title>
            </Helmet>
            <ErrorBoundary>
                <Provider>
                    <Div100vh className="d-flex flex-column">
                        <div className="d-flex flex-row align-items-center p-1">
                            <div className="fs-3 me-2">
                                <Logo width="1em" height="1em" viewBox="0 0 512 512" />
                            </div>
                            <div>
                                <h1 className="fs-3 m-0">{PRODUCT_TITLE}</h1>
                            </div>
                        </div>
                        <MapToolWrapper />
                    </Div100vh>
                </Provider>
            </ErrorBoundary>
        </>
    );
}


function MapToolWrapper() {
    // Skip rendering the map entirely during SSR

    if (typeof window === 'undefined') {
        console.warn("Cannot find window object. Not rendering during SSR.");
        return null;
    }

    return <MapToolApp />;
}


function MapToolApp() {

    const [selectedTab, selectTab] = React.useState(TAB_MAP);
    const [toolbarMenuOpen, setToolbarMenuOpen] = React.useState(false);

    // const [mapCenter, setMapCenter] = React.useState([45, 0]);
    // const [mapZoom, setMapZoom] = React.useState(6);
    const uiState = useSelector(({ uiState = {} }) => uiState);
    const {
        map: {
            zoom: mapZoom = 6,
            center: mapCenter = [45, 0],
        } = {}
    } = uiState;
    const setMapCenter = center => dispatch(actions.uiState.map.setCenter(center));
    const setMapZoom = zoom => dispatch(actions.uiState.map.setZoom(zoom));

    // Points of interest to show on the map
    // const [points, setPoints] = React.useState(DEFAULT_POINTS);
    const dispatch = useDispatch();

    const poiGroups = useSelector(state => state?.poiGroups || []);
    // const points = useSelector(({ points = [] }) => points);
    // const locations = points.map(point => point.location);

    // Configuration for the picker tool
    const pickerTool = usePickerTool(() => selectTab(TAB_MAP));

    // ---------------------------------------------------------------

    // App context

    const appContext = {
        selectedTab,
        selectTab,
        pickerTool,
        closeModal: () => selectTab(TAB_MAP),
    };

    // ---------------------------------------------------------------

    const onMapClick = event => {
        const { lat, lng } = event.latlng;
        pickerTool.onPickLocation({ lat, lng });
    };

    /**
       Toolbar for the "map" view
     */
    const renderToolbar = () => {
        const className = [
            "bg-dark",
            "my-1",
            "d-flex",
            "flex-row",
            "align-items-center",
            "justify-content-center",
        ].join(" ");
        return (
            <div className={className}>

                <div className="ms-1">
                    <CreatePointMenu
                        pickerTool={pickerTool}
                        selectedTab={selectedTab}
                        selectTab={selectTab}
                    />
                </div>

                <Button
                    className="ms-1"
                    onClick={() => selectTab(TAB_POINTS)}
                    active={selectedTab === TAB_POINTS}
                >
                    <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                    <span className="d-none d-md-inline">Points</span>
                </Button>

                <div className="flex-grow-1" />

                <div className="me-1">{renderToolbarMenu()}</div>

            </div>
        );
    };

    const renderToolbarMenu = () => {
        // toolbarMenuOpen, setToolbarMenuOpen
        return (
            <Dropdown isOpen={toolbarMenuOpen} toggle={() => setToolbarMenuOpen(x => !x)}>
                <DropdownToggle color="primary">
                    <FontAwesomeIcon icon={faBars} />{" "}
                    <span className="d-none d-md-inline">Menu</span>
                </DropdownToggle>
                <DropdownMenu>

                    <DropdownItem
                        onClick={() => selectTab(TAB_EXPORT)}
                        active={selectedTab === TAB_EXPORT}
                    >
                        <FontAwesomeIcon icon={faDownload} />{" "}
                        Export
                    </DropdownItem>

                    <DropdownItem
                        onClick={() => selectTab(TAB_IMPORT)}
                        active={selectedTab === TAB_IMPORT}
                    >
                        <FontAwesomeIcon icon={faUpload} />{" "}
                        Import
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem
                        onClick={() => selectTab(TAB_SETTINGS)}
                        active={selectedTab === TAB_SETTINGS}
                    >
                        <FontAwesomeIcon icon={faCog} />{" "}
                        Settings
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem
                        onClick={() => selectTab(TAB_ABOUT)}
                        active={selectedTab === TAB_ABOUT}
                    >
                        <FontAwesomeIcon icon={faQuestionCircle} />{" "}
                        About {PRODUCT_TITLE}
                    </DropdownItem>

                </DropdownMenu>
            </Dropdown>        );
    };

    const renderPickerMessage = () => {
        const className = [
            "bg-white", "text-black", "p-2",
            "d-flex", "justify-content-between", "align-items-center",
            "position-absolute", "top-0", "start-0", "end-0",
        ].join(" ");
        return (
            <div className={className} style={{ zIndex: 200 }}>
                <div>{pickerTool.label}</div>
                <Button onClick={pickerTool.deactivate}>
                    Cancel
                </Button>
            </div>
        );
    };

    const renderMapData = (poiGroups) => {
        return poiGroups.map((group, idx) =>
            <POIGroupMapData key={group.id} idx={idx} group={group} />
        );
    };

    function POIGroupMapData({ idx, group }) {
        const theme = getPOIGroupTheme(group, idx);
        const { points } = group;
        return (
            <React.Fragment>
                {renderPOIs(points, { theme })}
                {renderPlottedRoutes(points, { theme })}
                {renderPlottedRadiuses(points, { theme })}
            </React.Fragment>
        );
    }

    const renderPOIs = (points, { theme }) => {
        return points.map((point, idx) =>
            <Marker
                key={idx}
                position={point.location}
                icon={makeCustomIcon({
                    text: (idx + 1),
                    themeName: theme.name,
                    size: 26,
                })}
            >
                <Popup>
                    <PointPopupContent
                        idx={idx}
                        point={point}
                        nextPoint={points[idx + 1] || null}
                        onDelete={() => dispatch(actions.points.remove(idx))}
                    />
                </Popup>
            </Marker>
        );
    };

    const renderPlottedRoutes = (points, { theme }) => {
        const locations = points.map(point => point.location);
        if (locations.length <= 1) {
            return null;
        }
        return (
            <GeodesicLine
                positions={locations}
                steps={DEFAULT_RESOLUTION}
                style={{ ...ROUTE_LINE_STYLE, ...theme.routeStyle }}
            />
        )
    };

    const renderPlottedRadiuses = (points, { theme }) => {
        return points.map((point, idx) => {
            if (!point.showRadius) {
                return null;
            }
            return (
                <GeodesicCircle
                    key={idx}
                    center={point.location}
                    radius={point.radius}
                    steps={DEFAULT_RESOLUTION}
                    style={{
                        ...DISTANCE_CIRCLE_STYLE,
                        ...theme.distanceStyle,
                    }}
                />);
        });
    };

    const renderMap = () => {
        return (
            <MapContainer
                center={mapCenter}
                zoom={mapZoom}
                className="flex-grow-1"
                zoomControl={false}
                attributionControl={false}
                style={{ cursor: 'crosshair', zIndex: 1 }}
            >

                <MapEventHandler
                    onClick={onMapClick}
                    onZoomEnd={(e, map) => setMapZoom(map.getZoom())}
                    onMoveEnd={(e, map) => setMapCenter(map.getCenter())}
                />

                <ZoomControl position="bottomleft" />
                <ScaleControl position="topright" />

                <OsmTileLayer />
                {renderMapData(poiGroups)}

            </MapContainer>
        );
    };

    const onModalClose = () => selectTab(TAB_MAP);

    return (
        <AppContextProvider value={appContext}>
            <div className="flex-grow-1 d-flex flex-column position-relative">

                {renderToolbar()}

                <div className="flex-grow-1 d-flex flex-column position-relative">
                    <div className="flex-grow-1 d-flex flex-column position-relative">
                        {pickerTool.isActive && renderPickerMessage()}
                        {renderMap()}
                    </div>

                    {selectedTab === TAB_POINTS &&
                        <PointsModal />}

                    {selectedTab === TAB_SETTINGS &&
                        <SettingsModal onClose={onModalClose} />}

                    {selectedTab === TAB_EXPORT &&
                        <ExportModal onClose={onModalClose} />}

                    {selectedTab === TAB_IMPORT &&
                        <ImportModal onClose={onModalClose} />}

                    {selectedTab === TAB_INPUT_COORDINATES &&
                        <CoordinatesInputModal onClose={onModalClose} />}

                    {selectedTab === TAB_ABOUT &&
                        <AboutModal onClose={onModalClose} />}
                </div>

            </div>
        </AppContextProvider>
    );
}


function PointPopupContent({ idx, point, nextPoint, onDelete }) {

    const renderDestination = () => {
        if (!nextPoint) {
            return null;
        }
        const nextPointDistance = GeoMath.inverse(point.location, nextPoint.location);
        return (
            <div className="mt-3">
                <div className="d-flex flex-row align-items-center">
                    <div className="me-1">To next point:</div>
                    <span className="me-1">
                        <FontAwesomeIcon
                            style={{ transform: `rotate(${nextPointDistance.initialBearing}deg)` }}
                            size="1x"
                            icon={faArrowAltCircleUp}
                        />
                    </span>
                    <code className="me-1">
                        {formatBearing(nextPointDistance.initialBearing)}
                    </code>{" "}
                    <code className="me-1">
                        ({formatDistance(nextPointDistance.distance)})
                    </code>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="fs-4">{point.label || <span className="text-muted">Unnamed point</span>}</div>
            <div className="fs-6">
                <code>{formatLatLonDMS(point.location)}</code>
            </div>
            {!!point.radius && <div>Radius: {formatDistance(point.radius)}</div>}
            {renderDestination()}
            <div className="mt-3 text-end">
                <Button color="danger" size="sm" onClick={onDelete}>
                    <FontAwesomeIcon icon={faTrashAlt} />{" "}Delete
                </Button>
            </div>
        </>
    );
}


function MapEventHandler({ onClick, onZoomEnd, onMoveEnd }) {
    const map = useMapEvents({
        click(event) {
            if (onClick) {
                onClick(event, map);
            }
        },
        zoomend(event) {
            if (onZoomEnd) {
                onZoomEnd(event, map);
            }
        },
        moveend(event) {
            if (onMoveEnd) {
                onMoveEnd(event, map);
            }
        }
    })
    return null;
}


function CreatePointMenu({
    pickerTool,
    selectedTab,
    selectTab,
}) {

    const [isOpen, setOpen] = React.useState(false);
    const dispatch = useDispatch();

    const renderPickerToolAddOne = () => {
        const isActive = pickerTool.isActive && pickerTool.name === PICKER_TOOL_ADD_ONE;
        const onClick = () => {
            if (isActive) {
                return pickerTool.deactivate();
            }
            pickerTool.activate(
                (location, tool) => {
                    const newPoint = createPoint({ location });
                    dispatch(actions.points.append(newPoint));
                    tool.deactivate();
                },
                { name: PICKER_TOOL_ADD_ONE },
            );
        };
        return (
            <DropdownItem onClick={onClick} active={isActive}>
                <FontAwesomeIcon icon={faPlusCircle} />{" "}
                Pick location from map
            </DropdownItem>
        );
    };

    const renderMultiPickerTool = () => {
        const isActive = pickerTool.isActive && pickerTool.name === PICKER_TOOL_ADD_MANY;
        const onClick = () => {
            if (isActive) {
                return pickerTool.deactivate();
            }
            pickerTool.activate(
                location => {
                    const newPoint = createPoint({ location });
                    dispatch(actions.points.append(newPoint));
                },
                { name: PICKER_TOOL_ADD_MANY, multi: true },
            );
        };
        return (
            <DropdownItem onClick={onClick} active={isActive}>
                <FontAwesomeIcon icon={faPlusCircle} />{" "}
                Pick multiple locations from map
            </DropdownItem>
        );
    };

    const doAddCurrentLocation = () => {
        const currentDate = new Intl.DateTimeFormat('en-GB', {
            dateStyle: 'full',
            timeStyle: 'short',
        }).format(new Date());

        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            const newPoint = createPoint({
                location: [latitude, longitude],
                label: `Location on ${currentDate}`,
            });
            dispatch(actions.points.append(newPoint));
        });
    };

    const renderAddCurrentLocationTool = () => {
        const onClick = () => doAddCurrentLocation();
        return (
            <DropdownItem onClick={onClick}>
                <FontAwesomeIcon icon={faGlobe} />{" "}
                Add current location
            </DropdownItem>
        );
    };

    const renderEnterCoordinatesTool = () => {
        return (
            <DropdownItem
                onClick={() => selectTab(TAB_INPUT_COORDINATES)}
                active={selectedTab === TAB_INPUT_COORDINATES}
            >
                <FontAwesomeIcon icon={faPencilAlt} />{" "}
                Enter coordinates
            </DropdownItem>
        );
    };

    return (
        <Dropdown isOpen={isOpen} toggle={() => setOpen(state => !state)}>
            <DropdownToggle color="success">
                <FontAwesomeIcon icon={faPlusCircle} />{" "}
                <span className="d-none d-md-inline">Add</span>
            </DropdownToggle>
            <DropdownMenu>
                {renderPickerToolAddOne()}
                {renderMultiPickerTool()}
                {renderAddCurrentLocationTool()}
                {renderEnterCoordinatesTool()}
            </DropdownMenu>
        </Dropdown>
    );
}


function usePickerTool(selectMap) {

    // Configuration for the picker tool
    const [pickerTool, setPickerTool] = React.useState({
        isActive: false,
        callback: null,
    });

    // ---------------------------------------------------------------

    // Function to activate the picker tool
    const activate = (callback, options) => {
        setPickerTool({
            isActive: true,
            callback,
            label: "Pick a location on the map",

            // Allow picking multiple times
            multi: false,

            ...options,
        });

        // Switch to the map tab
        selectMap && selectMap();
    };

    const deactivate = () => {
        setPickerTool({
            isActive: false,
        });
    };

    const onPickLocation = ({ lat, lng }) => {
        if (pickerTool.isActive) {
            if (pickerTool.callback) {
                pickerTool.callback([lat, lng], { deactivate });
            }
            if (!pickerTool.multi) {
                deactivate();
            }
        }
    };

    return {
        ...pickerTool,
        activate,
        deactivate,
        onPickLocation,
    };
}
