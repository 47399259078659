export const points = {
    append: (newItem) =>
        ({ type: "points:append", newItem }),
    update: (idx, changes) =>
        ({ type: "points:update", idx, changes }),
    remove: (idx) =>
        ({ type: "points:remove", idx }),
    moveUp: (idx) =>
        ({ type: "points:moveUp", idx }),
    moveDown: (idx) =>
        ({ type: "points:moveDown", idx }),
    // assign: (points) =>
    //     ({ type: "points:assign", points }),
};


export const poiGroups = {
    append: (newItem) =>
        ({ type: "poiGroups:append", newItem }),
    update: (idx, changes) =>
        ({ type: "poiGroups:update", idx, changes }),
    moveUp: (idx) =>
        ({ type: "poiGroups:moveUp", idx }),
    moveDown: (idx) =>
        ({ type: "poiGroups:moveDown", idx }),
    remove: (idx) =>
        ({ type: "poiGroups:remove", idx }),
    upsertById: (item) =>
        ({ type: "poiGroups:upsertById", item }),
};


export const uiState = {
    map: {
        setCenter: center => ({ type: "uiState.map.setCenter", center }),
        setZoom: zoom => ({ type: "uiState.map.setZoom", zoom }),
    },
    pointsDialog: {
        update: updates => ({ type: "uiState.pointsDialog.update", updates }),
    },
};


export const settings = {
    update: settings => ({ type: "settings.update", settings }),
};
