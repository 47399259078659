import * as React from "react";
import Div100vh from "react-div-100vh";
import { Button } from "reactstrap";
import { ReactComponent as Logo } from "../../images/logo.svg";


export default function DefaultErrorComponent() {
    return (
        <Div100vh className="d-flex flex-column justify-content-center align-items-center text-center">
            <div>
                <div className="d-flex flex-row justify-content-center align-items-center p-1">
                    <div className="fs-3 me-2">
                        <Logo width="1em" height="1em" viewBox="0 0 512 512" />
                    </div>
                    <div>
                        <h1 className="fs-3 m-0">
                            ZenGIS
                        </h1>
                    </div>
                </div>
                <h1>Something went wrong.</h1>
                <p>
                    If the error persists, you can try resetting all data.
                </p>
                <p>
                    <strong>WARNING!</strong> All the ZenGIS data
                    stored in this browser will be lost.
                </p>
                <div className="my-3">
                    <FullResetButton />
                </div>
            </div>
        </Div100vh>
    );
}


function FullResetButton() {

    function doFullReset() {
        localStorage.clear();
        window.location.reload();
    }

    return (
        <Button color="danger" onClick={() => doFullReset()}>
            Reset all data
        </Button>
    );
}
