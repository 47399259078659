import * as React from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    UncontrolledAccordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
} from "reactstrap";
import { useDispatch } from "react-redux";
import * as uuid from "uuid";

import * as actions from "../storage/actions";
import { useSettings } from "../lib/settings";
import { formatDate } from "../lib/formatting";
import { useSelector } from "react-redux";


export default function ImportModal({ onClose }) {
    return (
        <Modal isOpen toggle={onClose} size="xl">
            <ModalHeader toggle={onClose}>
                Import
            </ModalHeader>
            <ModalBody>
                <FileImportFullUI />
            </ModalBody>
        </Modal>
    );
}


function FileImportFullUI() {

    const [importedData, setImportedData] = React.useState(null);

    const handleFiles = files => {
        if (files.length < 1) {
            return;
        }
        if (files.length > 1) {
            console.warn("Received multiple files, only using the first one", files);
        }
        let file = files[0];
        let reader = new FileReader();
        reader.onload = event => {
            let text = event.target.result;
            let json = JSON.parse(text);
            setImportedData(json);
        };
        reader.readAsText(file);
    };

    if (importedData) {
        return (
            <DataImportUI
                data={importedData}
                onCancel={() => setImportedData(null)}
            />
        );
    }

    return (
        <>
            <div className="mb-3">
                <Input type="file" onChange={evt => handleFiles(evt.target.files)} />
            </div>
            <FileDropArea onFilesDropped={files => handleFiles(files)} />
        </>
    );
}


function FileDropArea({ onFilesDropped }) {

    const [isActive, setActive] = React.useState(false);

    const onDragOver = event => {
        event.preventDefault();
    };

    const onDragEnter = event => {
        setActive(true);
    };

    const onDragLeave = event => {
        setActive(false);
    };

    const onDragEnd = event => {
        setActive(false);
    };

    const onDrop = event => {
        event.stopPropagation();
        event.preventDefault();
        const files = event.dataTransfer.files;
        onFilesDropped(files);
    }

    let props = { onDragEnter, onDragLeave, onDragEnd, onDragOver, onDrop };
    let className = [
        "border border-3 rounded text-center",
        "d-flex flex-column justify-content-center align-items-center",
        isActive ? "border-primary" : "border-secondary",
    ];

    return (
        <div
            style={{ minHeight: 200 }}
            className={className.join(" ")}
            {...props}
        >
            <span>Drop file here</span>
        </div>
    );
}


function DataImportUI({ data, onCancel }) {

    if (!data.zengis) {
        return (
            <div>Not a valid zengis file</div>
        );
    }

    if (data.zengis.version !== 1) {
        return (
            <div>Unsupported version</div>
        );
    }

    return (
        <>
            <div className="mb-3">
                ZenGIS file version {data.zengis.version},
                created on {formatTimestamp(data.zengis.exportTimestamp)}.
            </div>

            <POIGroupsImportUI poiGroups={data.data?.poiGroups} />

            <div className="mt-3 d-flex justify-content-between">
                <Button onClick={onCancel}>
                    Choose a different file
                </Button>
            </div>
        </>
    );
}


function POIGroupsImportUI({ poiGroups }) {
    if (!poiGroups.length) {
        return null;
    }
    return (
        <div>
            <h2>Point groups</h2>
            <UncontrolledAccordion
                stayOpen
            >
                {poiGroups.map((group, idx) => (
                    <POIGroupImportUI key={group.id || idx} group={group} />
                ))}
            </UncontrolledAccordion>
        </div>
    );
}


function POIGroupImportUI({ group }) {

    const dispatch = useDispatch();
    const poiGroups = useSelector(state => state?.poiGroups || []);
    const existingGroup = poiGroups.find(x => x.id === group.id);

    const [importResult, setImportResult] = React.useState({
        done: false,
        success: null,
    });

    const onImport = () => {
        // Import the layer, overwriting if needed
        // If a layer already exists with the same label, create a
        // unique one for the layer being imported, to reduce confusion.
        let newLabel = createUniqueName(group.label, poiGroups.map(group => group.label));
        let newGroup = {
            ...group,
            label: newLabel,
        };
        dispatch(actions.poiGroups.upsertById(newGroup));
        setImportResult({ done: true, success: true });
    };

    const onImportAsNew = () => {
        // Import as a new layer
        let newLabel = createUniqueName(group.label, poiGroups.map(group => group.label));
        let newGroup = {
            ...group,
            label: newLabel,
            id: uuid.v4(),
        };
        dispatch(actions.poiGroups.append(newGroup));
        setImportResult({ done: true, success: true });
    };

    const renderGroupInfo = (group) => {
        return (
            <div className="mb-3">
                Points group, containing {group.points.length} points.
            </div>
        );
    };

    const renderImportActions = () => {
        if (importResult.done) {
            if (importResult.success) {
                return <DataImportSuccessMessage
                    onDismiss={() => setImportResult({ done: false })} />
            }
            return <DataImportErrorMessage
                onDismiss={() => setImportResult({ done: false })} />
        }

        if (existingGroup) {
            return (
                <Alert color="warning">
                    A group with the same ID already exists:{" "}
                    {existingGroup.label}{" "}
                    (containing {existingGroup.points.length} points).
                    <div className="mt-3">
                        <Button onClick={onImport} color="danger">
                            Overwrite
                        </Button>{" "}
                        <Button onClick={onImportAsNew} color="primary">
                            Import as new
                        </Button>
                    </div>
                </Alert>
            );
        }

        return (
            <div>
                <Button color="primary" onClick={onImport}>
                    Import this POI group
                </Button>
            </div>
        );
    };

    return (
        <AccordionItem>
            <AccordionHeader targetId={`poi-group-${group.id}`}>
                {group.label || "Untitled group"}
            </AccordionHeader>
            <AccordionBody accordionId={`poi-group-${group.id}`}>
                {renderGroupInfo(group)}
                {renderImportActions()}
            </AccordionBody>
        </AccordionItem>
    );
}


function DataImportSuccessMessage({ onDismiss }) {
    return (
        <Alert color="success">
            <div className="d-flex justify-content-between align-items-center">
                <div>Data was imported successfully</div>
                <Button color="success" onClick={onDismiss}>
                    Import again
                </Button>
            </div>
        </Alert>
    );
}


function DataImportErrorMessage({ onDismiss }) {
    return (
        <Alert color="danger">
            <div className="d-flex justify-content-between align-items-center">
                <div>Error trying to import data</div>
                <Button color="danger" onClick={onDismiss}>
                    Try again
                </Button>
            </div>
        </Alert>
    );
}


function formatTimestamp(timestamp) {
    let date = new Date(timestamp);
    return formatDate(date, {
        dateStyle: "medium",
        timeStyle: "short",
        hour12: false,
    });
}


function createUniqueName(baseName, existingNames) {
    const enset = new Set(existingNames);
    if (!enset.has(baseName)) {
        // No name conflicts, we're good to go
        return baseName;
    }

    let newIdx = 1;
    while (true) {
        let newName = `${baseName} (${newIdx})`;
        if (!enset.has(newName)) {
            return newName;
        }
        newIdx += 1;
    }
}
