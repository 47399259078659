import {
    faHome,
    faUtensils,
    faCar,
    faGasPump,
    faParking,
    faStar,
    faClinicMedical,
    faTractor,
    faPizzaSlice,
    faFootballBall,
    faBowlingBall,
    faFutbol,
    faAnchor,
    faAppleAlt,
    faExclamationTriangle,
    faInfoCircle,
    faQuestionCircle,
    faMapMarker,
    faCrosshairs,
    faGlobe,
} from '@fortawesome/free-solid-svg-icons';


export const THEMES = [
    {
        "name": "red",
        "markerStyle": {
            "color": "#fff",
            "background": "#f44336"
        },
        "routeStyle": {
            "color": "#f44336"
        },
        "distanceStyle": {
            "color": "#f44336",
            "fillColor": "#f44336"
        }
    },
    {
        "name": "pink",
        "markerStyle": {
            "color": "#fce4ec",
            "background": "#e91e63"
        },
        "routeStyle": {
            "color": "#e91e63"
        },
        "distanceStyle": {
            "color": "#e91e63",
            "fillColor": "#e91e63"
        }
    },
    {
        "name": "purple",
        "markerStyle": {
            "color": "#f3e5f5",
            "background": "#9c27b0"
        },
        "routeStyle": {
            "color": "#9c27b0"
        },
        "distanceStyle": {
            "color": "#9c27b0",
            "fillColor": "#9c27b0"
        }
    },
    {
        "name": "deep-purple",
        "markerStyle": {
            "color": "#ede7f6",
            "background": "#673ab7"
        },
        "routeStyle": {
            "color": "#673ab7"
        },
        "distanceStyle": {
            "color": "#673ab7",
            "fillColor": "#673ab7"
        }
    },
    {
        "name": "indigo",
        "markerStyle": {
            "color": "#e8eaf6",
            "background": "#3f51b5"
        },
        "routeStyle": {
            "color": "#3f51b5"
        },
        "distanceStyle": {
            "color": "#3f51b5",
            "fillColor": "#3f51b5"
        }
    },
    {
        "name": "blue",
        "markerStyle": {
            "color": "#e3f2fd",
            "background": "#2196f3"
        },
        "routeStyle": {
            "color": "#2196f3"
        },
        "distanceStyle": {
            "color": "#2196f3",
            "fillColor": "#2196f3"
        }
    },
    {
        "name": "light-blue",
        "markerStyle": {
            "color": "#e1f5fe",
            "background": "#03a9f4"
        },
        "routeStyle": {
            "color": "#03a9f4"
        },
        "distanceStyle": {
            "color": "#03a9f4",
            "fillColor": "#03a9f4"
        }
    },
    {
        "name": "cyan",
        "markerStyle": {
            "color": "#e0f7fa",
            "background": "#00bcd4"
        },
        "routeStyle": {
            "color": "#00bcd4"
        },
        "distanceStyle": {
            "color": "#00bcd4",
            "fillColor": "#00bcd4"
        }
    },
    {
        "name": "teal",
        "markerStyle": {
            "color": "#e0f2f1",
            "background": "#009688"
        },
        "routeStyle": {
            "color": "#009688"
        },
        "distanceStyle": {
            "color": "#009688",
            "fillColor": "#009688"
        }
    },
    {
        "name": "green",
        "markerStyle": {
            "color": "#e8f5e9",
            "background": "#4caf50"
        },
        "routeStyle": {
            "color": "#4caf50"
        },
        "distanceStyle": {
            "color": "#4caf50",
            "fillColor": "#4caf50"
        }
    },
    {
        "name": "light-green",
        "markerStyle": {
            "color": "#f1f8e9",
            "background": "#8bc34a"
        },
        "routeStyle": {
            "color": "#8bc34a"
        },
        "distanceStyle": {
            "color": "#8bc34a",
            "fillColor": "#8bc34a"
        }
    },
    {
        "name": "lime",
        "markerStyle": {
            "color": "#f9fbe7",
            "background": "#cddc39"
        },
        "routeStyle": {
            "color": "#cddc39"
        },
        "distanceStyle": {
            "color": "#cddc39",
            "fillColor": "#cddc39"
        }
    },
    {
        "name": "yellow",
        "markerStyle": {
            "color": "#000",
            "background": "#ffeb3b"
        },
        "routeStyle": {
            "color": "#ffeb3b"
        },
        "distanceStyle": {
            "color": "#ffeb3b",
            "fillColor": "#ffeb3b"
        }
    },
    {
        "name": "amber",
        "markerStyle": {
            "color": "#fff8e1",
            "background": "#ffc107"
        },
        "routeStyle": {
            "color": "#ffc107"
        },
        "distanceStyle": {
            "color": "#ffc107",
            "fillColor": "#ffc107"
        }
    },
    {
        "name": "orange",
        "markerStyle": {
            "color": "#fff3e0",
            "background": "#ff9800"
        },
        "routeStyle": {
            "color": "#ff9800"
        },
        "distanceStyle": {
            "color": "#ff9800",
            "fillColor": "#ff9800"
        }
    },
    {
        "name": "deep-orange",
        "markerStyle": {
            "color": "#fbe9e7",
            "background": "#ff5722"
        },
        "routeStyle": {
            "color": "#ff5722"
        },
        "distanceStyle": {
            "color": "#ff5722",
            "fillColor": "#ff5722"
        }
    },
    {
        "name": "brown",
        "markerStyle": {
            "color": "#efebe9",
            "background": "#795548"
        },
        "routeStyle": {
            "color": "#795548"
        },
        "distanceStyle": {
            "color": "#795548",
            "fillColor": "#795548"
        }
    },
    {
        "name": "gray",
        "markerStyle": {
            "color": "#fafafa",
            "background": "#9e9e9e"
        },
        "routeStyle": {
            "color": "#9e9e9e"
        },
        "distanceStyle": {
            "color": "#9e9e9e",
            "fillColor": "#9e9e9e"
        }
    },
    {
        "name": "blue-gray",
        "markerStyle": {
            "color": "#eceff1",
            "background": "#607d8b"
        },
        "routeStyle": {
            "color": "#607d8b"
        },
        "distanceStyle": {
            "color": "#607d8b",
            "fillColor": "#607d8b"
        }
    }
];

const MARKER_ICONS = [
    { name: "american-football", icon: faFootballBall },
    { name: "anchor", icon: faAnchor },
    { name: "apple", icon: faAppleAlt },
    { name: "bowling", icon: faBowlingBall },
    { name: "car", icon: faCar },
    { name: "clinic", icon: faClinicMedical },
    { name: "farm", icon: faTractor },
    { name: "gas-pump", icon: faGasPump },
    { name: "home", icon: faHome },
    { name: "parking", icon: faParking },
    { name: "pizza", icon: faPizzaSlice },
    { name: "restaurant", icon: faUtensils },
    { name: "soccer", icon: faFutbol },
    { name: "star", icon: faStar },
    { name: "star", icon: faStar },
    { name: "warning", icon: faExclamationTriangle },
    { name: "info", icon: faInfoCircle },
    { name: "question", icon: faQuestionCircle },
    { name: "marker", icon: faMapMarker },
    { name: "crosshairs", icon: faCrosshairs },
    { name: "globe", icon: faGlobe },
];


const _THEMES_BY_NAME = Object.fromEntries(
    THEMES.map(x => [x.name, x])
);


const _MARKER_ICONS_BY_NAME = Object.fromEntries(
    MARKER_ICONS.map(x => [x.name, x])
);


function getMarkerThemeByGroupIdx(groupId) {
    const THEME_NAMES = [
        // Use a nicer order
        "indigo",
        "red",
        "teal",
        "yellow",
        "blue",
        "pink",
        "green",
        "amber",
        "light-blue",
        "purple",
        "light-green",
        "orange",
        "cyan",
        "deep-purple",
        "lime",
        "deep-orange",
    ];
    const themeName = THEME_NAMES[groupId % THEME_NAMES.length];
    // return THEMES.find(x => x.name === themeName);
    return _THEMES_BY_NAME[themeName];
}


export function getPOIGroupTheme(group, groupIdx) {
    if (group.themeName) {
        const theme = _THEMES_BY_NAME[group.themeName];
        if (theme) {
            return theme;
        }
    }
    return getMarkerThemeByGroupIdx(groupIdx);
}


export function getThemeByName(name) {
    return _THEMES_BY_NAME[name];
}


export function getMarkerIconByName(name) {
    return _MARKER_ICONS_BY_NAME[name];
}
