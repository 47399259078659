import * as React from "react";
import * as ReactDOMServer from 'react-dom/server';
import * as L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getThemeByName, getMarkerIconByName } from "../themes";


export default function makeCustomIcon({
    themeName = "indigo",
    iconName = null,
    text = null,
    size = 30,
} = {}) {

    const theme = getThemeByName(themeName);

    const renderContent = () => {
        if (iconName) {
            const icon = getMarkerIconByName(iconName);
            if (icon) {
                return <FontAwesomeIcon icon={icon.icon} />;
            }
        }
        return text;
    };

    const markerStyle = {
        background: "#3f51b5",
        color: "#fff",

        fontSize: size * 0.5,
        borderRadius: "50%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: size,
        height: size,

        ...theme.markerStyle,
    };

    return L.divIcon({
        html: (
            ReactDOMServer.renderToString(
                <div style={markerStyle}>
                    {renderContent()}
                </div>
            )
        ),
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
        popupAnchor: [0, -20],
        tooltipAnchor: [0, -20],
        className: "",
    });
}
