import * as React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import { ReactComponent as Logo } from "../../images/logo.svg";


export default function AboutModal({ onClose }) {
    return (
        <Modal isOpen toggle={onClose} size="lg">
            <ModalHeader toggle={onClose}>
                <Logo width="40px" height="40px" viewBox="0 0 512 512" />{" "}
                About ZenGIS
            </ModalHeader>
            <ModalBody>
                <p>
                    ZenGIS is a simple, web-based GIS (Geographical
                    Information System) software.
                </p>
                <p>
                    All the data is stored locally, using the browser's localStorage.
                </p>
                <hr/>
                <p>
                    Copyright &copy; {(new Date()).getFullYear()}{" "}
                    <a href="https://www.hackzine.org" target="_blank" rel="noreferrer">
                        Sam Santi
                    </a>.
                    All rights reserved.
                    Hosted version free for personal use.
                </p>
                <p>
                    Inquiries at: samuele at samuelesanti dot com.
                </p>
            </ModalBody>
        </Modal>
    );
}
