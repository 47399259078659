/**
   Functions to construct various state objects.

   Mainly make sure objects have defaults set properly.
 */


import * as uuid from "uuid";
import { normalizeLatLon } from "../lib/math";


export function createPoint(data) {
    const { location } = data;
    return {
        id: uuid.v4(),
        timestamp: (new Date()).toISOString(),
        label: "",
        showRadius: false,
        radius: 0,
        ...data,
        location: location ? normalizeLatLon(location) : [0, 0],
    };
}


export function createPointsGroup(data) {
    return {
        id: uuid.v4(),
        label: "",
        points: [],
        visible: true,
        ...data,
    };
}
