import ArrayTool from "../lib/array-tool";
import reducePOIGroups from "./reducers/poi-groups";
import reducePoints from "./reducers/points";


const DEFAULT_STATE = {
    points: [],
};


export default function reducer(state = {}, action) {
    // console.log("REDUCER", state, action);

    if (action.type === "@@INIT") {
        return { ...DEFAULT_STATE, ...state };
    }

    // Action format: ``path.to.data:action``
    const actionParts = action.type.split(":");
    const [ actionPath, actionName ] = actionParts;

    if (actionPath === "poiGroups") {
        const subState = state?.poiGroups;
        const newSubState = reducePOIGroups(subState, actionName, state, action);
        return { ...state, poiGroups: newSubState };
    }

    if (actionPath === "points") {
        const subState = state?.poiGroups;
        const newSubState = reducePoints(subState, actionName, state, action);
        return { ...state, poiGroups: newSubState };
    }

    switch (action.type) {

        // UI state --------------------------------------------------

        case "uiState.map.setCenter":
            return {
                ...state,
                uiState: {
                    ...state?.uiState,
                    map: {
                        ...state?.uiState?.map,
                        center: action.center,
                    },
                },
            };

        case "uiState.map.setZoom":
            return {
                ...state,
                uiState: {
                    ...state?.uiState,
                    map: {
                        ...state?.uiState?.map,
                        zoom: action.zoom,
                    },
                },
            };

        case "uiState.pointsDialog.update":
            return reduce_uiState_pointsDialog_update(state, action);

        case "settings.update":
            return {
                ...state,
                settings: {
                    ...state?.settings,
                    ...action.settings,
                },
            };

        default:
            return state;
    }
}


function reduce_uiState_pointsDialog_update(state, action) {
    const { updates } = action;
    return {
        ...state,
        uiState: {
            ...state?.uiState,
            pointsDialog: {
                ...state?.uiState?.pointsDialog,
                ...updates,
            },
        },
    };
}


// function subUpdate(state, name, updater) {
//     const prevState = state?.[name];
//     return {
//         ...state,
//         [name]: { ...prevState, ...updater(prevState) },
//     };
// }
//

// subUpdate(
//     state, 'uiState', uiState => subUpdate(
//         uiState, 'pointsDialog', pointsDialog => subUpdate(
//             () => updates
//         )
//     )
// );
